<template>
    <div class="border shadow" style="margin: 0 auto;width: 900px;">
        <h2>Terms of Service</h2>
        <p>Last updated: June 18th, 2022</p>
        <p>Welcome to the Terms of Service (hereinafter these "Terms"). This Terms of Service applies to the following services.</p>
        <ul class="policy-ul">
            <li>Our website and user accounts that may be accessed at several portals, such as akuvoxsmartplus.com, akuvox.com,akubela.com. (The 'Website');</li>
            <li>Online services accessible through the Website ('Web Services');</li>
            <li>Software applications that can be downloaded to a mobile device ('Mobile Apps'), such as Akuvox SmartPlus, BelaHome, BelaGo, and so on;</li>
            <li>Related products ('Devices') of the service, including, but not limited to, door phones, indoor monitors, android video phones, access control terminals, and home control panels;</li>
            <li>Any services available on the Website and the Mobile Apps ('Available Services').</li>
            <li>The term 'SERVICE' means the Website, Web Services, Mobile Apps, Devices and Available Services.</li>
        </ul>
        <p>The 'SERVICE' is provided by SMART-PLUS PTE. LTD. (hereinafter "SMART-PLUS", "we" or "us") a.s. Company, Id. No. (Tax Id. No) 202116626G with registered office in 6 RAFFLES QUAY #14-06, Singapore (048580), and these Terms are concluded between SMART-PLUS and the user (hereinafter "you")
            using the SERVICE.</p>
        <p>Acceptance of these Terms by you is required for you to be able to access and use the SERVICE. By accessing or using the SERVICE, you consent to be bound by and become a party to this agreement. Please read them carefully, as they include important information about your legal rights and
            obligations. If you do not understand or agree to these Terms, please do not use the SERVICE.</p>

        <h3>Privacy Policy</h3>
        <p>Your access to and use of the SERVICE is also conditioned on your acceptance of and compliance with the SERVICE' Privacy Policy. The Privacy Policy describes our policies and procedures on the collection, usage and disclosure of your personal information when you use our SERVICE and tells
            you about your privacy rights and how you are protected by relevant laws and regulations. Please read our Privacy Policy carefully before using our SERVICE.</p>

        <h3>User Account</h3>
        <p>If you want to create a service account, you must provide us with accurate, complete, and current information at all times. Failure to do so constitutes a breach of the Terms, resulting in the immediate termination of your account on our SERVICE.</p>
        <p>If your account has been created by your organization (such as your employer), your organization may be able to change or deactivate your account, and access, modify or delete your data. We are not responsible for your organization’s internal administration. Please consult your
            organization's administrator about the usage of the SERVICE</p>
        <p>You are responsible for safeguarding the password that you use to access the SERVICE and for any activities or actions under your password with our SERVICE.</p>
        <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any security breach or unauthorized use of your account.</p>

        <h3>Your Rights</h3>
        <p>You have the right to use the applications provided to you as part of the SERVICE on the condition that you comply with these Terms. Specifically speaking, you have the right to download, install and operate the applications on electronic devices you own for personal, non-commercial use
            only and in accordance with these terms. And your access to and use of the SERVICE may be temporarily interrupted for a variety of reasons, including, but not limited to, equipment failures, network failures, periodic updates, maintenance or repair services, or other actions announced by
            us.</p>

        <h3>Your Responsibilities</h3>
        <p>Your use of the SERVICE must comply with these Terms, including, but are not limited to:</p>
        <p>1. You represent that you are over the age of 18. We do not permit those under 18 to use the SERVICE. If you are under 18 years of age, you have to first obtain the approval from your parents or guardians before using the SERVICE;</p>
        <p>2. You may not use as a username of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or
            obscene;</p>
        <p>3. The SERVICE are for personal use only. You may not exploit it for any commercial purpose;</p>
        <p>4. You may not modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, or offer for sale any information contained on, or obtained from or through, the SERVICE;</p>
        <p>5. You may not decompile, reverse engineer or attempt to reconstruct, identify or discover any source code of the SERVICE (including any underlying idea or algorithm), or attempt to do any of the same;</p>
        <p>6. You may not use automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to modify the SERVICE;</p>
        <p>7. You may not use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo, image, or other proprietary notation displayed on or through the SERVICE;</p>
        <p>8. You may not access or use the SERVICE in any manner that could disable, overburden, damage, disrupt or impair the SERVICE or interfere with any other party's access to or use of the SERVICE or use of any device, software or routine that causes the same;</p>
        <p>9. You may not attempt to gain unauthorized access to, interfere with, damage or disrupt the SERVICE, accounts registered to other users, or the computer systems or networks connected to the SERVICE, or circumvent, remove, alter, deactivate, degrade or thwart any technological measure or
            content protections of the SERVICE;</p>
        <p>10. You may not use any robot, spider, crawlers or other automatic device, process, software or queries that intercepts, "mines," scrapes or otherwise accesses the SERVICE to monitor, extract, copy or collect information or data from or through the SERVICE, or engage in any manual process
            to do the same;</p>
        <p>11. You may not introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious or technologically harmful into our systems;</p>
        <p>12. You may not use the SERVICE for illegal harassing, unethical or disruptive purposes, or violate any applicable law or regulation in connection with your access to or use of the SERVICE, including without limitation any applicable data privacy laws; or access or use of the SERVICE in
            any way not expressly permitted by these Terms.</p>

        <h3>Paid Accounts</h3>
        <p>If you want to purchase any services, you can choose whether to renew automatically. If you have enabled automatic renewal, we will automatically charge your payment method on each agreed-upon periodic renewal until you cancel. You can cancel your paid subscription at any time.
        </p>

        <h3>Intellectual Property</h3>
        <p>We provide you with the SERVICE through several applications, such as Akuvox SmartPlus, BelaHome, BelaGo, which are all strictly Software-as-a-Service (SaaS). All rights, legal claims and shares in any patents, trademarks, industrial or utility models, copyrights, business secrets and
            other rights to intellectual and industrial property applying to the SERVICE belong to AKUVOX(XIAMEN) NETWORKS CO., LIMITED (hereinafter "AKUVOX") and SMART-PLUS PTE. LTD., which does not affect your right to be granted a license to the SERVICE. Provision of the SERVICE does not
            establish any authorization to use a trademark, corporate name, patent, industrial or utility model or known-how owned by AKUVOX or SMART-PLUS. You shall not use any rights related to intellectual or industrial property owned by AKUVOX or SMART-PLUS.</p>

        <h3>User Feedback</h3>
        <p>You agree to grant us a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit feedback you provide to us regarding the SERVICE without restriction. Such feedbacks include, but are not
            limited to, any enhancement suggestions, feature requests, or any other feedback regarding the SERVICE.</p>

        <h3>Links to Other Websites</h3>
        <p>Our SERVICE may contain links to third-party websites or services not owned or controlled by us.</p>
        <p>We have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged
            to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
        <p>We strongly advise that you read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

        <h3>Termination</h3>
        <p>A violation of the Terms and any unauthorized use of the SERVICE will automatically constitute a material breach. If the user breaches the terms or otherwise fails to comply with the terms of the SERVICE, we may, terminate the user account, delete the user account, and restrict and/or
            limit the user's access to and/or use of the service in our sole discretion. But before we do so, we will give you reasonable advance notice so that you may request that your data be exported from our systems. In the case of account deletion, you are prohibited from registering for the
            SERVICE using a different name.</p>

        <h3>Limitation of Liability</h3>
        <p>Unless prohibited by laws, you agree that the SERVICE will not be liable for damages of any kind, including, but are not limited to, direct, indirect, special, exemplary, incidental, and consequential or punitive damages, no matter how the damages are caused and no matter under what
            theory of liability, whether arising from contract, strict liability, tort (including negligence or otherwise) or any other cause of action. These circumstances include, but are not limited to:</p>
        <p>(i) your inability to access any buildings or premises;</p>
        <p>(ii) your granting of any individual into any buildings or premises resulting in damage to or loss of any personal property;</p>
        <p>(iii) personal injury or loss of life arising from your use of the SERVICE;</p>
        <p>(iv) any loss of data, business, or profits arising from your use of any third-party websites and services on the SERVICE;</p>
        <p>(v) any other claims or damages resulting from, arising out of, or in connection with the use of the SERVICE.</p>
        <p>Notwithstanding the preceding, if the SERVICE is held liable to you by law, then the SERVICE' total liability for any damages ultimately caused to you arising out of or related to the SERVICE shall not exceed $100.</p>

        <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
        <p>The SERVICE is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the SERVICE, on its own behalf, expressly disclaims all warranties, whether express, implied, statutory or
            otherwise, concerning the SERVICE, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of the course of dealing, course of performance, usage or trade practice. Without limitation to the
            preceding, the SERVICE provides no warranty or undertaking, and makes no representation of any kind that the SERVICE will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption,
            meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected.</p>

        <h3>Governing Law</h3>
        <p>The laws of Singapore, excluding its conflicts of law rules, shall govern these Terms and your use of the SERVICE. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
        <p>
            For European Union (EU) Users<br/>
            If You are a European Union user, you will benefit from any mandatory provisions of the law of the country in which you are resident.
        </p>
        <p>
            For United States Users<br/>
            If You are a United States user, you will benefit from any mandatory provisions of the law of the country in which you are resident.
        </p>

        <h3>Disputes Resolution</h3>
        <p>If you have any concerns or disputes about the SERVICE, you agree to first try to resolve the dispute informally by contacting us. We will try to resolve the dispute informally by contacting you via e-mail in 7 days.</p>

        <h3>Severability and Waiver</h3>
        <p>
            Severability<br/>
            If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law. The remaining provisions will continue in full force and effect.
        </p>
        <p>
            Waiver<br/>
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of
            any subsequent breach.
        </p>

        <h3>Changes to the Terms</h3>
        <p>At our sole discretion, we reserve the right to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole
            discretion.</p>
        <p>By continuing to access or use our SERVICE after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the SERVICE.</p>

        <h3>Contact Information</h3>
        <p>If you have any questions about these Terms of Service, you can contact us:</p>
        <ul class="policy-ul">
            <li>By visiting this page on our website: https://www.akuvoxsmartplus.com/</li>
            <li>By sending us an email: sales@akuvoxsmartplus.com</li>
        </ul>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped>
	h1,h2,h3,h4,h5,p,li{
		padding: 18px 0 0 0;
	}
	ul,li{
		list-style: disc;
	}
	.policy-ul {
		left: 5%;
		position: relative;
		width: 90%;
	}
</style>
